const querystring = require('querystring');

module.exports = {
    trackEvent(category, action, label) {
        if (!window || !window.ga) {
            return;
        }
        window.ga('send', {
            hitType: 'event',
            eventCategory: category,
            eventAction: action,
            eventLabel: label
        });
    },
    trackCustomPage(page, title) {
        if (!window || !window.ga) {
            return;
        }
        window.ga('send', {
            hitType: 'pageview',
            page,
            title
        });
    },
    trackSocial(network, target) {
        if (!window || !window.ga) {
            return;
        }
        window.ga('send', {
            hitType: 'social',
            socialNetwork: network,
            socialAction: 'share',
            socialTarget: target
        });
    },
    getPageLabel() {
        if (!window || !window.ga) {
            return;
        }
        let pathName = window.document.location.pathname;
        pathName = pathName.split('/');
        if (!pathName.length) {
            return;
        }
        switch(pathName[1]) {
            case '':
                return 'Homepage';
            case 'about-us':
                return 'About Us-Why ParentWise';
            case 'join-the-movement':
                return 'About Us-Join the Movement';
            case 'our-story':
                return 'About Us-Our Story';
            case 'wise-journey':
                return 'WiseJourney Landing Page';
            case 'wise-self':
                return 'WiseJourney-WiseSelf';
            case 'wise-speak':
                return 'WiseJourney-WiseSpeak';
            case 'wise-tips':
                const queryStr = querystring.parse(window.document.location.search.replace('?', ''));
                if (queryStr['type'] && queryStr['type'] === 'coreFinding') {
                    return 'Core Findings Landing Page';
                }
                return 'WiseTips Landing Page';
            case 'wise-tip':
                return `WiseTips-[${pathName[2]}]`;
            case 'core-finding':
                return `Core Findings-[${pathName[2]}]`;
            case 'super-honestly':
                if (pathName[2]) {
                    return `SuperHonestly-[${pathName[2]}]`;
                } else {
                    return 'SuperHonestly Landing Page';
                }
            case 'about-our-domains':
                return 'WiseTips-About Our Domains';
            case 'contact-us':
                return 'Contact Us';
            case 'privacy-policy':
                return 'Privacy';
            case 'site-map':
                return 'Sitemap';
            case 'search':
                return 'Search';
            default:
                if (pathName[2]) {
                    return `${pathName[1]}-[${pathName[2]}]`;
                } else {
                    return `${pathName[1]}`;
                }
        }
    },
};
