import React, { useState, useEffect, Fragment, useContext } from "react";
import { Link,  graphql, navigate } from "gatsby";
import Modal from '../components/common/Modal';
import LeftChevronIcon from '../images/icons/arrow.png';
import SEO from "../components/seo";
import SendOTP from '../components/Auth/SendOTP';
import VerifyOTP from '../components/Auth/VerifyOTP';
import ProfileForm from '../components/RegisterForm/Profile';
import UserFeelForm from '../components/RegisterForm/UserFeelForm';
import PreferenceForm from '../components/RegisterForm/PreferenceForm';
import PhoneVerification from '../components/RegisterForm/PhoneVerification';
import { api } from '../../utils';
import '../components/Auth/auth.scss';
import UserContext from '../context/UserContext';

const Auth = ({ data }) => {

    const userData = useContext(UserContext);

    const [isFetching, setIsFetching] = useState(false);
    const [user, setUser] = useState();
    const [type, setType] = useState('register');
    const [formData, setFormData] = useState({});
    let [currentStep, setCurrentStep] = useState(1);
    const [currentView, setCurrentView] = useState('SEND_OTP');
    const [screen, setScreen] = useState(false);

    useEffect(() => {
        navigate("/404");
        // fetchUserDetail();
        // return () => {
        //     document.documentElement.classList.remove("pw-no-scroll");
        // }
    }, []);

    useEffect(() => {
        if (user && (!user.phone_number || !user.phone_verified_at || user.duplicate_user_id)) {
            setType('register');
            return;
        }
        if (user && user.current_step) {
            setType('register');
            if (user.current_step > 4) {
                userData.setUserData(user);
                navigate('/profile', { replace: true });
                return;
            } 
            setCurrentStep(screen ? 3 : user.current_step);
            setCurrentView('PROFILE_UPDATE');
        }
        if (user) {
            userData.setUserData(user);
        }
    }, [user, screen]);

    const fetchUserDetail = () => {
        setIsFetching(true);
        api.get('/api/v1/auth/user')
        .then(resp => {
            setIsFetching(false);
            if (resp && resp.success && resp.data) {
                setUser(resp.data);
            }
        });
    }

    const handleClose = () => {
        document.documentElement.classList.remove("pw-no-scroll");
        window.history.back();
    }

    const handleOtpResponse = (data) => {
        if (data) {
            if (data.userExists) {
                setType('login');
            } else {
                setType('register');
            }
            setFormData({
                ...formData,
                ...data
            });
            setCurrentView('VERIFY_OTP');
        }
    };

    const handleGoBack = () => {
        if (currentView === 'SEND_OTP') {
            handleClose();
        } else if (currentView === 'VERIFY_OTP') {
            setCurrentView('SEND_OTP');
        } else if (currentView === 'PROFILE_UPDATE') {
            if (currentStep <= 2) {
                handleClose();
            }
            setCurrentStep(--currentStep);
        }
    }

    const renderCurrentView = () => {

        if (user) {
            switch(currentStep) {
                case 1:
                    return (
                        <PhoneVerification setUser={setUser} user={user} />
                    );
                case 2:
                    return (
                        <ProfileForm setUser={setUser} user={user} setScreen={setScreen} />
                    );
                case 3:
                    return (
                        <UserFeelForm setUser={setUser} user={user} setScreen={setScreen} />
                    );
                case 4:
                    return (
                        <PreferenceForm setUser={setUser} data={data} user={user} />
                    );
                default: return null;
            }
        }

        switch(currentView) {
            case 'SEND_OTP':
                return (
                    <SendOTP type={type} handleResponse={handleOtpResponse} />
                );
            case 'VERIFY_OTP':
                return (
                    <VerifyOTP type={type}
                        data={formData}
                        setUser={setUser}
                        handleOtpResponse={handleOtpResponse}
                        handleGoBack={handleGoBack}
                    />
                );
            default : return null;
        }
    }

    if (isFetching) {
        return (
            <div className="w3-center w3-padding-32" style={{ minHeight: '100vH' }}>
                <div className="pw-loader" />
            </div>
        )
    }

    return (
        // <Fragment>
        //     {/* <SEO title="Authentication" /> */}
        //     <Modal
        //         openCls="w3-show"
        //         customClass="bg-color"
        //         customStyle={{
        //             width:'100%',
        //             minHeight:'100vh',
        //             padding:0,
        //             margin:0,
        //         }}
        //         modalStyle={{
        //             padding:0,
        //             margin:0
        //         }}
        //     >
        //         <div className="w3-display-container">
        //             <div className="pw-modal-cross" onClick={() => handleClose()} />
        //         </div>
        //         <div className="pw-register">
        //             <div className="pw-register-top">
        //                 <button onClick={handleGoBack}>
        //                     <img src={LeftChevronIcon} className="pw-register-top-back-icon" />
        //                 </button>
        //                 <button
        //                     className={`pw-register-top-text reg-margin ${(type == 'register') ? 'active' : ''}`}
        //                     onClick={() => {
        //                         if (currentView === 'VERIFY_OTP' || user) {
        //                             return;
        //                         }
        //                         setType('register')
        //                     }}
        //                 >
        //                     New to Parentwise?
        //                 </button>
        //                 <button
        //                     className={`pw-register-top-text reg-padding ${(type == 'login') ? 'active' : ''}`}
        //                     onClick={() => {
        //                         if (currentView === 'VERIFY_OTP' || user) {
        //                             return;
        //                         }
        //                         setType('login')
        //                     }}
        //                 >
        //                     Already Registered?
        //                 </button>
        //             </div>
                    
        //             {renderCurrentView()}

        //                 <div className="w3-center desc" style={{width:'100%'}}>By registering you are accepting the {' '}
        //                     {/* <Link to='/terms-of-use' style={{color: "#F1C555"}}>terms & conditions</Link> and {' '} */}
        //                     <Link to='/privacy-policy' style={{color: "#F1C555"}}>privacy policy</Link> of ParentWise
        //                 </div>

        //             {(type === 'register') && (
        //                 <div className="stepper">
        //                     {
        //                         [1,2,3,4].map(i => (
        //                             <div key={i}
        //                                 className={`numberCircle ${(i < 4) ? 'margin ': ''} ${(i <= currentStep) ? 'active ': ''}`}>
        //                                     {i}
        //                                 </div>
        //                             )
        //                         )
        //                     }
        //                 </div>
        //             )}
        //         </div>
        //     </Modal>

        // </Fragment> */}
        <h1>The Page doesn't exist....</h1>
    )
}

export const query = graphql`
  {
    cms {
      ageStages {
        nodes {
          description
        }
      }
    }
  }
`

export default Auth;