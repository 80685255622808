import React,{ useEffect } from 'react';
import './modal.scss';

const Modal = (props) => {

    const { customStyle, customClass, children, customBg,
        openCls, modalStyle, showCloseBtn, handleOverlayClick, handleClose, hideOverLay } = props;
    
    useEffect(() => {
        if (openCls === 'w3-show') {
            document.documentElement.className = 'pw-no-scroll';
        } else {
            document.documentElement.classList.remove("pw-no-scroll");
        }
    }, [openCls]);

    const handleOnClick = (e) => {
        e.preventDefault();
        handleOverlayClick && handleOverlayClick();
    }

    const handleModalClose = (e) => {
        e.preventDefault();
        if (handleClose) {
            handleClose();
        } else {
            handleOnClick(e);
        }
    }

    return (
        <div className={`w3-modal ${openCls} ${customBg || ''}`}
            style={modalStyle || {}}>
            {!hideOverLay && <div
                onClick={e => handleOnClick(e)}
                className="pw-modal-overlay"
            ></div>}
            <div
                className={`w3-modal-content ${customClass || ''}`}
                style={customStyle ? Object.assign({}, customStyle, { position: 'relative' }) : {}}
            >
                {showCloseBtn && (
                    <div className="pw-modal-cross"
                        onClick={e => handleModalClose(e)}
                    />
                )}
                {children}
            </div>
        </div>
    );
}

export default Modal