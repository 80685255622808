import React, { useState } from "react"
import DropDown from '../common/DropDown';
import { api } from '../../../utils';
import './../Auth/auth.scss';
import Emoji1 from '../../images/icons/emoji-1.png';
import Emoji2 from '../../images/icons/emoji-2.png';
import Emoji3 from '../../images/icons/emoji-3.png';
import Emoji4 from '../../images/icons/emoji-4.png';
import Emoji5 from '../../images/icons/emoji-5.png';
import Emoji6 from '../../images/icons/emoji-6.png';

const emojis = [
    { label: 'happy', value: Emoji1 },
    { label: 'joy', value: Emoji2 },
    { label: 'sad', value: Emoji3 },
    { label: 'cry', value: Emoji4 },
    { label: 'angry', value: Emoji5 },
    { label: 'very_angry', value: Emoji6 }
]

const UserFeelForm = (props) => {
    const { setUser, user, setScreen } = props;

    const initialData = {
        relation: (user && user.relationship) ? user.relationship : 'a parent',
        childCount: (user && user.number_of_children) ? user.number_of_children : '1',
        childCareTime: (user && user.child_care_time) ? user.child_care_time : 'full time',
        feeling: (user && user.feeling) ? emojis.find((i) => i.label == user.feeling).value : '',
    }
    const [isSending, setIsSending] = useState(false);
    const [formData, setFormData] = useState(initialData);
    const [errors, setErrors] = useState({});
    const relationships = [
        { value: 'a parent' },
        { value: 'a grandparent' },
        { value: 'an aunty' },
        { value: 'an uncle' },
        { value: 'a caregiver' },
    ];
    const types = [
        { value: 'full time' },
        { value: 'during working hours' },
        { value: 'after working hours' },
        { value: 'certain days of the week' },
        { value: 'only on weekends' },
    ];
    const numbers = [ { value: '1' }, { value: '2' }, { value: '3' }, { value: '4' }, { value: '5' },
                { value: '6' }, { value: '7' }, { value: '8' }, { value: '9' }, { value: '10' }, 
    ];

    const handleErrors = (name) => {
        const err = Object.assign({}, errors);
        if (err[name]) {
            err[name] = null;
        }
        setErrors(err);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
        handleErrors(name);
    }

    const validateData = () => {
        let hasError = false;
        Object.keys(formData).forEach((key) => {
          if (!formData[key]) {
            hasError = true;
            errors[key] = 'This field is required';
          }
        });
        if (hasError) {
            setErrors({...errors});
          return false;
        }
        return formData;
    }

    const handleSelect = (name, selected) => {
        setFormData({
            ...formData,
            [name]: selected
        });
        handleErrors(name);
    }

    const handleSubmit = () => {
        const validData = validateData();
        if (!validData) {
            return;
        }

        const fData = {
            relationship: formData.relation,
            numberOfChild: formData.childCount,
            childCareTime: formData.childCareTime,
            feeling: emojis.find((i) => i.value === formData.feeling).label
        }
        setIsSending(true);
        api.post('/api/v1/auth/update/user-feel', fData)
        .then(resp => {
            setIsSending(false);
            if (resp && resp.success) {
                setUser(resp.data);
                setScreen(false);
            } else {
                alert(resp.message);
            }
        });
    }
    
    return ( <>
        <div className="w3-center pw-detail-title">
            Help us meet you where you are.
        </div>
        <div className="pw-rf-detail">
            <div>I am </div>
             &nbsp;
            <div className="w3-center" style={{width: '150px'}}>
                <DropDown
                    placeholder="a parent"
                    options={relationships}
                    customCls="pw-cf-input"
                    selectedValue={formData.relation}
                    onChange={(e) => handleSelect('relation', e)}
                    containerCls="option-container-txt"
                    valueCls="value-selected"
                />
                <div className="pw-error-text">{errors.relation}</div>
            </div> &nbsp; &nbsp;
            <div>of</div> &nbsp; &nbsp;
            <div style={{width: '80px'}}>
                <DropDown
                    placeholder="1"
                    options={numbers}
                    customCls="pw-cf-input"
                    selectedValue={formData.childCount}
                    onChange={(e) => handleSelect('childCount', e)}
                    containerCls="option-container-txt"
                /> 
                <div className="pw-error-text">{errors.childCount}</div>
            </div>
        </div>
        <div className="pw-rf-detail">
            child/children. I look after my child/children
        </div>
        <div className="pw-rf-detail">
            <div style={{width: '150px'}}>
                <DropDown
                    placeholder="full time"
                    options={types}
                    customCls="pw-cf-input"
                    selectedValue={formData.childCareTime}
                    onChange={(e) => handleSelect('childCareTime', e)}
                    containerCls="option-container-txt"
                    valueCls="value-selected"
                />
                <div className="pw-error-text">{errors.childCareTime}</div>
            </div> &nbsp; &nbsp;
            <div>. I feel</div> &nbsp; &nbsp;
            <div style={{width: '110px'}}>
                <DropDown
                    placeholder="EMOJIS"
                    options={emojis}
                    customCls="pw-cf-input"
                    selectedValue={formData.feeling}
                    onChange={(e) => handleSelect('feeling', e)}
                    emoji={true}
                    containerCls="option-container-emj"
                    optionCls="option-emj"
                /> 
                <div className="pw-error-text">{errors.feeling}</div>
            </div>
        </div>
        <div className="pw-rf-detail">
            <div>about being </div> &nbsp; &nbsp;
            <div classNme="w3-center" style={{width: '120px'}}>
                <input className="w3-input pw-cf-input"
                    type="text"
                    name="relation"
                    value={formData.relation}
                    disabled={true}
                />
                <div className="pw-error-text">{errors.relation}</div>
            </div> &nbsp; &nbsp;
            <div>today.</div>
        </div>
        <div className="w3-col s12 m12 pw-rf-submit-section">
            <button className="w3-round-xxlarge w3-button pw-rf-submit-btn" onClick={handleSubmit}>
                <div className="pw-rf-sub-btn-text">
                    Next <span className="pw-rf-sub-btn-arrow" />
                </div>
            </button>
        </div>
    </> )
}

export default UserFeelForm;
