import React, { useState } from "react"
import Logo1 from '../../images/icons/rf-profile.png';
import Logo2 from '../../images/icons/rf-profile-1.png';
import Logo3 from '../../images/icons/rf-profile-3.png';
import Logo4 from '../../images/icons/rf-profile-2.png';
import './../Auth/auth.scss';
import Slider from "react-slick";
import AVATAR_TYPES from '../../../constants/avatar-types';
import RadioButton from '../common/RadioButton';
import { emailValidator, textValidator, api } from '../../../utils';

const profileIcons = [Logo1, Logo2, Logo3, Logo4]
const profileImageMap = AVATAR_TYPES.map((card, idx) => ({
    ...card,
    icon: profileIcons[idx]
}))

const ProfileForm = (props) => {
    const { setUser, user, setScreen } = props;

    const initialData = {
        name: (user && user.full_name) ? user.full_name : '',
        email: (user && user.email) ? user.email : '',
        subscribe: (user && user.current_step > 2 && !user.email_subscription_id) ? false : true,
    }
    const [isSending, setIsSending] = useState(false);
    const [currentProfileIdx, setCurrentProfileIdx] = useState(0);
    const [formData, setFormData] = useState(initialData);
    const [errors, setErrors] = useState({});

    let avatarIndex = null;
    AVATAR_TYPES.forEach((card, idx) => {
        if(card.name == (user && user.avatar_type) ? user.avatar_type : '') {
            avatarIndex = idx;
        }
    });

    const settings = {
        className: "pw-rf-slider",
        dots: false,
        arrows: true,
        slidesToShow: 1,
        initialSlide: avatarIndex,
    };

    const handleErrors = (name) => {
        const err = Object.assign({}, errors);
        if (err[name]) {
            err[name] = null;
        }
        setErrors(err);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
        handleErrors(name);
    }

    const handleClick = () => {
        setFormData({
            ...formData,
            subscribe: !formData.subscribe ? true : false,
        });
    }

    const validateData = () => {
        const skipList = [
            'subscribe'
        ];
        let hasError = false;
        if (!emailValidator(formData.email)) {
            hasError = true;
            errors.email = 'Enter a valid email.';
        }
        if (!textValidator(formData.name)) {
            hasError = true;
            errors.name = 'Enter a valid name.';
        }
        Object.keys(formData).forEach((key) => {
            const pos = skipList.indexOf(key);
            if (pos < 0) {
                if (!formData[key]) {
                    hasError = true;
                    errors[key] = 'This field is required';
                }
            }
        });
        if (hasError) {
            setErrors({...errors});
            return false;
        }
        return formData;
    }

    const handleSubmit = () => {
        const validData = validateData();
        if(!validData){
            return;
        }

        const fData = {
            name: formData.name,
            email: formData.email,
            avatar_type: profileImageMap[avatarIndex ? avatarIndex : currentProfileIdx].name,
            subscribe: formData.subscribe
        }

        setIsSending(true);
        api.post('/api/v1/auth/update/profile-basic', fData)
        .then(resp => {
            setIsSending(false);
            if (resp && resp.success) {
                setUser(resp.data);
                setScreen(true);
            } else {
                alert(resp.message);
            }
        });
    }

    const profileList = profileImageMap.map((item, i) => (
        <div className="pw-rf-pr" key={i}>
            <div className="pw-rf-pr-img-main">
                <img className="pw-rf-pr-img" src={item.icon} />
            </div>
            <div className="w3-center pw-rf-pr-title">{item.title}</div>
            <div className="w3-center pw-rf-pr-desc">{item.content}</div>
        </div>
    ))

    const List = ( <>
        <Slider {...settings} afterChange={(idx) => setCurrentProfileIdx(idx)}>
            {profileList}
        </Slider>
    </>)
    
    return ( <>
        <div className="w3-center pw-profile-title">
            Select your preferred avatar
        </div>
        {List}
        <div className="pw-rf-profile">
            <div className="pw-rf-input">
                <input className="w3-input pw-rf-input-val"
                    type="text"
                    name="name"
                    value={formData.name}
                    placeholder="Full Name"
                    onChange={(e) => handleChange(e)}
                />
                <div className="pw-error-text">{errors.name}</div>
            </div>
            <div className="pw-rf-input">
                <input className="w3-input pw-rf-input-val"
                    type="text"
                    name="email"
                    value={formData.email}
                    placeholder="Email"
                    onChange={(e) => handleChange(e)}
                    disabled={(user && user.email_verified_at) ? true : false}
                />
                <div className="pw-error-text">{errors.email}</div>
            </div>
            <div className="pw-rf-radio-btn">
                <RadioButton
                    key={''}
                    label={'I want to receive news and future updates from ParentWise'}
                    labelCls={true}
                    value={formData.subscribe}
                    checked={formData.subscribe}
                    onChange={(e) => handleClick(e)}
                    circleCls="pw-rf-radio-circle pw-rf-radio-circle-margin" 
                    circleCheckedCls="pw-rf-radio-check-circle"
                    labelCls="pw-rf-radio-label"
                />
            </div>
        </div>
        <div className="w3-col s12 m12 pw-rf-submit-section">
            <button className="w3-round-xxlarge w3-button pw-rf-submit-btn" onClick={handleSubmit}>
                <div className="pw-rf-sub-btn-text">
                    Next <span className="pw-rf-sub-btn-arrow" />
                </div>
            </button>
        </div>
    </> )
}

export default ProfileForm
